export enum RoleFunctionalities {

    addEditWorkFlow = 1,
    addEditUser = 2,
    ListUser = 4,
    OfflineBC = 5,
    ListWorkFlow = 6,
    UploadOfficepool = 7,
    UploadAccountCRM = 8,
    UploadCustomerContact = 49,
    ReminderEmail = 50,
    TakeBack = 9,
    Correction = 10,
    ProductRequest = 11,
    BcList = 12,
    AddEditProduct = 18,
    ListTag = 19,
    TagProduct = 24,
    ListProduct = 25,
    ITRequest = 26,
    AccountRequest = 27,
    OfficeRequest = 28,
    Approve = 29,  // not set in pages
    ListAccount = 30,
    AccountSplitting = 31,
    EditAccount = 32,
    AddEditTags = 33,
    ListBill = 34,
    InvoiceBill = 35,
    BCRequest = 36,
    NeedTrainingManagement = 37,
    BillingFrequencyManagement = 38,
    EmailTemplateManagement = 39,
    UnitManagement = 40,
    NotificationManagement = 41,
    ViewImplementedProduct = 42,
    PendingContracts=51,
    OfflineBCRequest = 43,
    PaymentReportConsolidated = 45,
    PaymentReportDetailed = 46,
    ListAllRequest = 47,
    ListAllActivities = 48,
    AccountManager = 44,
    BillingUpdate = 13

}
